import React, { useState} from 'react'
import {useNavigate} from 'react-router-dom'

// css
import styles from './main.module.css'
import global from '../../../global.module.css'

// media
import imag from '../../../asserts/background/Режисер на фоне белых песков с чистым небом ЧБ с тенью.jpg'
import imag1 from '../../../asserts/background/Съемка фильма на фоне белых песков, где в кадре лишь один человек.jpg'
import imag2 from '../../../asserts/background/Режисер сидит на стуле на белом фоне с тенью.jpg'
import imag3 from '../../../asserts/background/Кинопленка на белом фоне ЧБ с тенью.jpg'
import imag4 from '../../../asserts/background/Кинопленка на фоне белых песков с белыми облаками ЧБ с тенью.jpg'
import imag5 from '../../../asserts/background/Кинопленка на фоне белых песков с белыми облаками ЧБ с тенью. Опретор в далеке.jpg'

//components
import GlassBox from '../../../components/items/glasses/glasses-box/GlassBox'
import Button from "../../../components/ui/buttons/button/Button";
import Footer from "../../../components/layouts/footer/Footer";
import {Helmet} from "react-helmet";
import {FULL_TITLE} from "../../../utils";
import {Particles} from "./particle/particle";
import {VelocityScroll} from "../../../components/items/scroll-text/VelocityScroll";

//DATA_CONST
const opportunities = [
    {
        number: '1.',
        title: 'Публикуйте посты',
        description: 'Зарабатывайте с каждой новой публикацией и увеличивайте свою аудиторию.'
    },
    {
        number: '2.',
        title: 'Закрытые публикации',
        description: 'Используйте видеохостинг с многоуровневой защитой контента и настройками приватности.'
    },
    {
        number: '3.',
        title: 'Система продажи видеоконтента',
        description: 'Продавайте свои видео прямо на платформе с удобной системой монетизации.'
    },
    {
        number: '4.',
        title: 'Общайтесь с аудиторией',
        description: 'Получайте ценную обратную связь от ваших подписчиков и укрепляйте связи с ними.'
    }
]
const getStart = [
    {
        id: 1001,
        title: 'Зарегистрируйся',
        description: 'Перейдите на страницу регистрации и создайте свой уникальный профиль.'
    },
    {
        id: 1002,
        title: 'Заполни свой профиль',
        description: 'Регистрация — это первый шаг. Теперь вам нужно детально заполнить профиль для полного доступа к функционалу.'
    },
    {
        id: 1003,
        title: 'Выложите первый пост',
        description: 'Придумайте привлекательный заголовок, выберите подходящие теги и создайте качественный контент.'
    },
    {
        id: 1004,
        title: 'Поделитесь с друзьями',
        description: 'Сообщите своим друзьям и подписчикам, что вы стали частью этого уникального сообщества.'
    },
]

const images = [
    imag2,
    imag1,
    imag3,
    imag4,
]

function Main() {

    const navigate = useNavigate()
    const [image, setImage] = useState(0)

    function handleChangeImage(param) {
        if (param === 'dec') {
            setImage(image - 1)
        } else {
            setImage(image + 1)
        }
    }

    const userAgent = navigator.userAgent;



    return (
        <div className={styles.back}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{FULL_TITLE}</title>
                <meta name="description"
                      content="Раскройте ваш творческий потенциал и поделитесь уникальными историями с миром! На нашем сайте мы, безусловно, помогаем авторам раскрыть свой потенциал"/>
                <meta name="keywords" content="HTML, CSS, JavaScript"/>
                <meta name="author" content="Sairommef"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
                <Particles className={styles.particle} size={userAgent.includes('Android') ? 0.1 : 0.4}
                           quantity={userAgent.includes('Android') ? 200 : 400}
                           ease={10} vy={0.1} color={'#000'} refresh/>
            <article className={styles.content}>
                <div className={`${styles.block}`} >
                    <div className={styles.title}>
                        <h1 className={`${global.xl8} ${global.bold}`}>{FULL_TITLE}</h1>
                        <p className={global.d3}>
                            Раскройте ваш творческий потенциал и поделитесь <br/> уникальными историями с миром!
                        </p>
                    </div>

                    <div className={`${global.flex} ${global.f_center} ${styles.navigation}`}>
                        <a href={'#start'}>
                            <Button variant={'outlet'}>
                                Пройти регистрацию
                            </Button>
                        </a>
                    </div>
                </div>

                <VelocityScroll numRows={1} defaultVelocity={2}>{FULL_TITLE.toUpperCase()}</VelocityScroll>

                <div className={`${global.flex}  ${styles.about}`} id={'about'}>

                    <div className={styles.leftTitle}>
                        <h1 className={`${global.xl9} ${global.bold}`}>Подробнее о нас</h1>
                        <p className={global.base}>
                            На нашем сайте мы, безусловно, помогаем <br/> авторам раскрыть свой
                            потенциал
                        </p>
                        <p className={global.base}>
                            - Предоставляем сервис для монетизации <br/> своих публикаций
                        </p>

                        <h6 className={global.bold}>
                            - У нас нет абонентской платы!
                        </h6>
                        <p className={global.base}>
                            - Проводим модерацию публикаций
                        </p>
                    </div>

                    <div className={styles.slider}>
                        <div className={styles.slider_content}>
                            <div className={styles.image} >
                                <img id={styles.sliter_image} src={images[image]} alt="Slide l"/>
                            </div>
                        </div>
                        <div className={`${global.flex} ${styles.buttons}`}>
                            <Button variant={'outlet'}
                                    click={() => handleChangeImage('dec')} disabled={image === 0}>
                                &#10094;
                            </Button>
                            <Button variant={'outlet'} click={() => handleChangeImage('inc')}
                                    disabled={image === images.length - 1}>
                                &#10095;
                            </Button>
                        </div>
                    </div>
                </div>

                <VelocityScroll numRows={1} defaultVelocity={2}>{FULL_TITLE.toUpperCase()}</VelocityScroll>

                <div className={`${global.flex}  ${styles.about}`}>

                    <div className={`${styles.image}`}>
                        <img src={imag} alt="About" className={styles.image} />
                    </div>
                    <div className={`${global.flex} ${global.f_dir_column} ${styles.temp} `}>
                        <div className={styles.leftTitle}>
                            <h2 className={`${global.xl7} ${global.bold}`}>Возможности сайта</h2>
                            <p className={global.base}>На нашем сайте мы, безусловно,<br/> помогаем авторам раскрыть
                                свой
                                потенциал</p>
                        </div>
                    <div className={styles.grid}>
                        {opportunities.map((opport, index) => (
                            <div className={styles.dep}>
                                <h1 className={`${global.medium} ${styles.header}`}>0{index}</h1>
                                <p className={`${global.bold} ${styles.littleHeader}`}>
                                    {opport.title}
                                </p>
                                <p className={global.d2}>
                                    {opport.description}
                                </p>
                            </div>
                        ))}
                    </div>
                    </div>

                </div>

                <div className={styles.centerLittle} id={'start'}>
                    <h1 className={`${global.xl7} ${global.bold}`}>Как начать?</h1>
                    <p className={global.base}>Главное ведь начать, а дальше положитесь на нас!</p>
                </div>

                <div className={`${styles.flex} ${styles.container}`}>
                    {getStart.map((start, i) => (
                        <GlassBox key={i}>
                            {/*<img /> Илюстрируещее изображение */}
                            {/*<input type="checkbox" className={styles.checkbox}*/}
                            {/*       id={start.id}/>*/}
                            <div className={styles.actions} >
                                <h1 className={`${global.bold} ${styles.header}`}>0{i + 1}</h1>
                                <p className={`${global.bold} ${styles.littleHeader}`}>
                                    {start.title}
                                </p>
                                <p className={`${styles.description} ${global.d3}`}>
                                    {start.description}
                                </p>
                            </div>
                        </GlassBox>
                    ))}
                </div>

                <Button variant={'color'} click={() => navigate('/registration')}>
                    Зарегистрироваться
                </Button>
            </article>

            <VelocityScroll numRows={1} defaultVelocity={2}>{FULL_TITLE.toUpperCase()}</VelocityScroll>


            <Footer/>
        </div>
    )
}

export default Main