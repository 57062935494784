import React from 'react'

// import global from '../../../../../global.module.css'
import SelectGroupTags from '../../../../../components/forms/tags/GroupTags/SelectGroupTags'


export default function SetUserInteresting() {

  return (
    <div>
      <SelectGroupTags type={'user-first'} />
    </div>
  )
}

