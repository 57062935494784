import React from 'react'

import SelectTags from '../../../../../components/forms/tags/Tags/SelectTags'

export default function BecomeAuthorCreativeTags () {

  return (
    <div>
      <SelectTags type={'user-to-author'} />
    </div>
  )
}

