import React from 'react';
import styles from "../price/becomeIP/become-author-plus.module.css";
import SettingsTitle from "../../../components/items/toolbar/settings-title/SettingsTitle";
import SettingsBlock from "../../../components/items/toolbar/settings-block/SettingsBlock";
import {Link} from "react-router-dom";
import global from "../../../global.module.css";

function Contact(props) {
    return (
        <div className={styles.documentation_main}>
            <SettingsTitle bigTitle={'Документация - Контакты'}/>
            <hr/>
            <SettingsBlock title={'Мобильный номер телефона'}>
                <blockquote>
                    <Link to={'tel: +79534911711'} className={styles.button}>
                        +79534911711
                    </Link>
                </blockquote>
            </SettingsBlock>


            <SettingsBlock title={'Почта'}>
                <blockquote>
                    <Link to={'mailto: four.and.one@yandex.ru'} className={styles.button}>
                        four.and.one@yandex.ru
                    </Link>
                </blockquote>
            </SettingsBlock>
            <hr/>

            <SettingsBlock title={'                    ИНН:'}>
                <blockquote>
                        1659180558
                </blockquote>
            </SettingsBlock>

            <SettingsBlock title={'ОГРН/ОГРНИП:'}>
                <blockquote>
                    1171690030599
                </blockquote>
            </SettingsBlock>

        </div>
    );
}

// А с доками че делать ?



export default Contact;