import React from 'react';
import global from "../../global.module.css";

function Bookmark({stroke = 'var(--black)', fill = 'transparent'}) {
    return (
        <div className={`${global.flex} ${global.f_center}`}>

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 21L12 17L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z"
                stroke={stroke} fill={fill} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div>
    );
}

export default Bookmark;