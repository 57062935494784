import React from 'react';
import {useNavigate} from "react-router-dom";

import styles from "../../price/becomeIP/become-author-plus.module.css";

import SettingsBlock from "../../../../components/items/toolbar/settings-block/SettingsBlock";
import SettingsTitle from "../../../../components/items/toolbar/settings-title/SettingsTitle";
import Button from "../../../../components/ui/buttons/button/Button";

function DauAvtorTags() {

    const navigate = useNavigate();

    return (
        <div className={styles.documentation_main}>
            <SettingsTitle bigTitle={'Документация - Зачем ещё раз выбирать теги?'}
                           description={'Выбор тегов при создании публикации важен по' +
                               ' нескольким причинам, и вот как это поможет вам и вашим читателям'}/>

            <hr/>

            <SettingsBlock title={'Рекомендации для пользователей:'}>
                <blockquote>
                    Теги помогают улучшить рекомендации.
                    Когда вы выбираете теги для своей публикации,
                    вы помогаете нашей системе предложить эту статью тем пользователям,
                    которые могут быть заинтересованы в подобной теме. Например, если вы выбираете тег
                    "Технологии", ваша публикация будет предложена пользователям,
                    интересующимся последними новинками в мире технологий.
                </blockquote>
            </SettingsBlock>

            <hr/>

            <SettingsBlock title={'Повышение видимости публикаций:'}>
                <blockquote >
                    Теги делают ваши публикации видимыми для широкой аудитории.
                    Правильный выбор тегов помогает пользователям быстрее найти ваши материалы,
                    улучшая взаимодействие с вашим контентом и привлекая больше читателей.
                </blockquote>
            </SettingsBlock>

            <hr/>
            <SettingsBlock >
                <Button variant={'outlet'} click={() => navigate(`/settings/author/group`)}>
                    Приступить к определению тегов
                </Button>
            </SettingsBlock>

        </div>
            );
}

export default DauAvtorTags;